import React, { useEffect, useState } from "react";
import random from "random";
import LabelImportantIcon from '@mui/icons-material/LabelImportant';


export const GenerateLevelOption = (x) => {
    var option = [];
    for (let i = 0; i <= x; i++) {
        option.push(<option key={i} value={i}>{i > 0 ? `Level ${i}` : 'Times Table Practice'}</option>);
    }
    return option;
}

export const GenerateRowOption = (x) => {
    var option = [];
    for (let i = 2; i <= x; i++) {
        option.push(<option key={i} value={i}>{`${i}`}</option>);
    }
    return option;
}


export const marks = [{
    value: 1500,
    label: "Faster"
},
{
    value: 9500,
    label: "Slower"
}]


// =================================================================

export const DisplayScreenHeading = ({ field }) => {
    var head;
    switch (field.operationObject.short_name) {
        case "a_s":
            head = "Addition & Subtraction";
            break;

        case "mul":
            head = field.operationObject.operation_type_id === 3 ? `${field.operationObject.digit[0]} Times Table Practice` : "Multiplication";
            break;

        case "div":
            head = "Division";
            break;

        case "lcm":
            head = "LCM";
            break;

        case "gcd":
            head = "GCD";
            break;

        case "per":
            head = "Percentage => [1st Number] % of [2nd Number]";
            break;

        case "sqrt":
            head = "Square Root";
            break;

        case "cbrt":
            head = "Cube Root";
            break;

        default:
            break;
    }
    return <h6 className="d-flex justify-content-center align-items-center"
        style={{
            color: field.fontColor,
            position: "absolute",
            top: ".5em", left: ".5em", letterSpacing: "1px"
        }}
    ><LabelImportantIcon className="pe-1" />{head}</h6>
}

// ====== CustomGenerateRandom is used to generate random array for difficult level  ============================

export const CustomGenerateRandom = (first_number_limit, second_number_limit, condition_type) => {
    var random_arr = [];
    // Generating first Number
    random_arr.push(random.int(first_number_limit[0], first_number_limit[1]));

    let secondNumber;
    // Generating second Number
    switch (condition_type) {
        case 0:
            secondNumber = random.int(second_number_limit[0], second_number_limit[1]);
            random_arr.push(secondNumber);
            break;

        case 1:
            const randomNum = Math.floor(Math.random() * second_number_limit.length);
            secondNumber = second_number_limit[randomNum];
            random_arr.push(secondNumber);
            break;

        default:
            break;
    }

    return random_arr;
}


// ========XXXX======= DisplayAnswer function used in Showing full answer with expression below ============XXXX======XXXX============

export const DisplayAnswer = (props) => {

    switch (props.operation) {
        case 'a_s':
            let str = props.array.join(' + ');
            let str2 = str.toString();
            let str3 = str2.replaceAll(" + -", " - ");
            return `${str3} = ${props.answer}`;

        case 'div':
            return `${props.array.join(' ÷ ')} = ${props.answer}`;

        case 'mul':
            return `${props.array.join(' X ')} = ${props.answer}`;

        case 'sqrt':
            return `Square Root of ${props.array} = ${props.answer}`;

        case 'lcm':
            return `LCM (${props.array.join(' , ')}) = ${props.answer}`;

        case 'per':
            return `${props.array.join(' % of ')} = ${props.answer}`;

        case 'gcd':
            return `GCD (${props.array.join(' , ')}) = ${props.answer}`;

        case 'cbrt':
            return `Cube Root of ${props.array} = ${props.answer}`;

        default:
            break;

    }
}


// ========XXXX=====XXXX======== Loader Component Used in Main Function ============XXXX======XXXX============

export const Loader = props => {

    const [index, setIndex] = useState(0);
    const [blank, setBlank] = useState(false);

    let num_display = props.flash; // Duration of displayed number on Screen
    let blank_display = props.timeout;  // Duration of blank Screen between two numbers in screen

    // ==========================
    // This UseEffect is linked with main Loader Function to display the numbers One by One
    useEffect(() => {

        let timeout;
        if (index < props.array.length - 1) {
            timeout = setTimeout(() => setBlank(true), num_display);
        }
        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.array, index]);

    // ==========================

    // This UseEffect is linked with ShowBlank Function, which display blank after each number display 
    useEffect(() => {

        let timeout;
        if (blank) {
            timeout = setTimeout(() => {
                setBlank(false);
                setIndex(index + 1);
            }, blank_display);
        }
        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [blank]);
    // ==========================

    return blank ? <div></div> : <div style={{ fontSize: "5em", color: props.color }}>{props.array[index]}</div>;
};
