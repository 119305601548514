import React from "react"

const Error =() =>{
   const style ={
    height:"100vh",
    // background: "#536976",  /* fallback for old browsers */
    // background:"-webkit-linear-gradient(to right, #292E49, #536976)",  /* Chrome 10-25, Safari 5.1-6 */
    background: "linear-gradient(to right, #292E49, #536976)", /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
   }
    
    return (
        <>
            <div className="d-flex align-items-center justify-content-center bg-fuchsia" style={style}>
                <div className="text-center text-white">
                    <h1 style={{fontSize:"10rem"}}>404</h1>
                    <h5 style={{fontSize:"2rem"}}>THE PAGE YOU WERE LOOKING FOR DOSEN'T EXIST.</h5>
                    <p style={{fontSize:"1rem", fontWeight:"bold"}}>The link you followed may be incorrect or has been expired.</p>
         
                </div>
            </div>

        </>
    )
}
export default Error