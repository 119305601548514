import React from "react"
const Footer = () => {
    const style = {
        backgroundColor: '#2C2E30',
        fontFamily: 'Mulish, sans-serif'
    }
    var thisYear = new Date().getFullYear();
    return (
        <div className="container-fluid" style={style}>
            <div className="d-flex container py-2 text-white-50 small"  >
                <span> &#169; Copyright {thisYear}. All Rights Reserved.</span>
                <span className="ms-auto">Version 10.5.0 | Development | Designed By
                    <a className="text-white-50 ms-2" target="_blank" rel="noopener noreferrer" href="https://portalwiz.com/">Portalwiz Technologies</a>.
                </span>
            </div>
        </div>
    )
}
export default Footer