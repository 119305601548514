import { CircularProgress } from "@mui/material"
import React from "react"
const LoadingSpinner = () => {
    return (
        <>
            <div className="d-flex justify-content-center align-items-center" style={{ position: "absolute", top: "50%", left: "50%" }}>
                <CircularProgress />
            </div>

        </>
    )
}

export default LoadingSpinner