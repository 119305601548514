import React from 'react';

import { equal } from "mathjs";
import { TableCell, TableRow } from '@mui/material';



/* This function returns an array of 2 Types of table Rows (In which Input fields are present as each cell) 

    +++++++++++++
    Type 1 - Table Row with Input fields where student will enter the answers [before submitting] the Test, Exam or Assignment

    +++++++++++++
    Type 2 - Table Row with [Readonly] Input fields where student will check his entered answers [after submitting] the Test, 
    Exam or Assignment, here wrong answer will have Red bordered INput and Right answer will have green bordered input.

*/

const GenerateInputRow = (props) => {

    const wrongStyle = { border: "2px solid red", width: "3em", textAlign: "center" }

    const rightStyle = { border: "2px solid green", width: "3em", textAlign: "center" }

    const ansField = { width: "3em", textAlign: "center" }

    const { from, to, onChange, length, field, readOnly, answer } = props;

    const checkAnswer = (studentAnswer, ActualAnswer) => {

        if (equal(studentAnswer, ActualAnswer)) {  // means '12.00'/'12.0'/'12.000000' etc. is equals to '12' & Vice-Versa.
            return true;
        }else {
            return false;
        }

    }

    let InputCells = [];

    // +++++++++ Case 2 +++++++++

    if (readOnly) {
        for (let i = from, j = 0; i <= to; i++, j++) {
            InputCells.push(
                <TableCell key={i} className={length < i ? "d-none" : "text-center"}>
                    <input
                        readOnly
                        name={`val${i}`}
                        style={checkAnswer(field[`val${i}`], answer[0][0][j]) ? rightStyle : wrongStyle}
                        value={field[`val${i}`]}
                    />
                </TableCell> 
            );
        }
    } else {

        // +++++++++ Case 1 +++++++++

        for (let i = from; i <= to; i++) {
            InputCells.push(
                <TableCell key={i} className={length < i ? "d-none" : "text-center"}>
                    <input
                        autoComplete="off"
                        type="number"
                        onChange={onChange}
                        name={`val${i}`}
                        style={ansField}
                        value={field[`val${i}`]}
                    />
                </TableCell>
            );
        }
    }
    return <TableRow>{InputCells}</TableRow>;
}

export default GenerateInputRow;
