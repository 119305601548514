// === BELOW commented object shows the key value pair Test object, of which keys are used below to generate errors.

/*{
    0: "ques_num1", 1: "ques_num2", 2: "ques_num3", 3: "ques_num4", 4: "ques_num5", 5: "ques_num6", 6: "ques_num7",
    7: "ques_num8", 8:"ques_num9", 9: "ques_num10", 10: "ques_num11", 11: "ques_num12", 12: "ques_num13", 13: "ques_num14",
    14: "ques_num15", 15: "ques_num16", 16: "answer", `17: "level_name", 18: "assignment_type", 19: "category_name",
    20 : "que_type", 21: "page"
}*/

const TestUploadValidator = (arrayOfObj, testType) => {
    var Test = testType;

    var errorArray = [];

    const GenerateError = (string) =>{
        errorArray.push(string);
    }

    // ======= Test Validator ============================ 
    const TestValidator = (arr, Test, index) =>{

        // The if operator returns false for null, undefined & blank variables.
        // So if var x is equal to null, undefined or an empty string than if(x) will return false and if(!x) will return true.
        // So by using if(!x) we will generate the error message. 

        if(!arr[0]) GenerateError(`Field 1 is required on row ${index +1}.`)
        if(!arr[1]) GenerateError(`Field 2 is required on row ${index +1}.`)
        if(!arr[16]) GenerateError(`Answer field is required on row ${index +1}.`)
        if(!arr[17]) GenerateError(`Level Name Field is required on row ${index +1}.`)
        
        if(Test === "Assignment" && !arr[18]) GenerateError(`Assignment type field is required on row ${index +1}.`)
        if((Test === "Test" || Test === "Exam" ) && !arr[19]) GenerateError(`Category name field is required on row ${index +1}.`)

        if(!arr[20]){ GenerateError(`Question type field is required on row ${index +1}.`)
        }else if(arr[20] !== Test){
            GenerateError(`Question type is invalid, it should be "${Test}" on row ${index +1}.`)
        }

        if(Test === "Assignment" && !arr[21]) GenerateError(`Page field is required on row ${index +1}.`)
       
    }

    // ======= Step 1 ====================================
    arrayOfObj.map((x, i) => TestValidator(x , Test, i));
    

    return errorArray;
}

export default TestUploadValidator;
