import { createSlice } from '@reduxjs/toolkit'

export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState: { value: false },
    reducers: {
        OPEN: (state, action) => {
            state.value = action.payload;
        },
        CLOSE: (state, action) => {
            state.value = action.payload;
        }
    }
})

export const { OPEN, CLOSE } = sidebarSlice.actions;

export default sidebarSlice.reducer;