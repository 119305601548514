import { configureStore } from '@reduxjs/toolkit';
import loginReducer from './loginSlice';
import profileNameReducer from './profileNameSlice';
import sidebarReducer from './sidebarSlice';

export default configureStore({
  reducer: {
    login: loginReducer,
    profileName: profileNameReducer,
    sidebar: sidebarReducer,
  },
})